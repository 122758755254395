import React, { Component, useCallback, useEffect, useState } from 'react'
import { Row, Col, Card, Tab, Tabs, Table, ProgressBar, Modal, Button, Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'


import avatar1 from '../../assets/images/user/avatar-1.jpg'

import clubLogo from '../../images/club_logo.svg'
import tombrasil from '../../assets/images/logos-tom-brasil.jpg'
import alma from '../../assets/images/logo-alma.png'



import { CONFIG } from '../../config/configVariables'
import { BASE } from '../../basedata/constants'
import { UserCreate } from '../components/userCreate'
// import { OrganizationCreate } from './createOrganization'
import { useForm } from 'react-hook-form'
import { EventCreate } from './createEvent'
import { EventList } from './eventList'

function Events() {
    // const auth = sessionStorage.getItem('auth')

    // if (auth !== 'true') {
    //   window.location.href = '/'
    // }

    // const [userList, setUserList] = useState<any[]>([])


    const dateConvert = (date: any) => {
        const eventDate = new Date(date)

        const options = {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
        }

        return `${eventDate.toLocaleDateString(
            'pt-br'
        )} ${eventDate.getHours()}:${eventDate.getMinutes().toString().padStart(2, '0')}`
    }

    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
        clearErrors,
        getValues,
        reset
    } = useForm()


    const [disableSign, setDisableSign] = useState(false)



    const userSyncProgress = (partialSync: any, totalSync: any) => {
        return Math.round((totalSync / partialSync) * 100);
    }

    const [isLoading, setLoading] = useState(false)

    const [totalUsers, setTotalUsers] = useState({
        withBiometry: 0,
        withoutBiometry: 0
    })

    const [show, setShow] = useState(false);



    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const [showOrg, setShowOrg] = useState(false);

    const handleShowOrg = () => setShowOrg(true)
    const handleCloseOrg = () => setShowOrg(false)


    const [showConfirm, setShowConfirm] = useState(false);

    const handleShowConfirm = () => setShowConfirm(true)
    const handleCloseConfirm = () => setShowConfirm(false)




    const onSubmit = (data: any) => {
        setDisableSign(true)

        // const username = string_to_slug(data.name)

        fetch(`${BASE.api.base_url}${BASE.api.access_create}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': BASE.api.api_key
            },
            body: JSON.stringify({
                name: data.name.replace(/^\s+|\s+$/g, ''),
                email: data.email.replace(/^\s+|\s+$/g, ''),
                password: `AM${Math.random().toString(36).slice(-8)}!`,
                role: data.type || 'default'
            })
        })
            .then((res) => res.json())
            .then((response) => {
                console.log(response)
            })
            .catch((error) => {
                console.log(error)
            })
    }


    const resetForm = () => {
        setDisableSign(false)
        reset()
    }




    return (
        <>
            <Row>
                <Col md={6} xl={8}>
                    <EventList />
                </Col>
                <Col md={6} xl={4}>
                    <EventCreate />
                </Col>

            </Row>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Criar coordenador</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row className="mb-3">

                            <Col>
                                <Form.Label>Nome</Form.Label>
                                <Form.Control
                                    placeholder="Nome Completo"
                                    {...register('name', { required: true })}
                                />
                                {errors.name && (
                                    <span className="label-error">
                                        Você precisa informar um Nome.
                                    </span>
                                )}</Col>
                        </Row>
                        <Row className="mb-3">
                            <Col><Form.Label>E-mail (opcional)</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="E-mail"
                                    {...register('email')}
                                />
                                {errors.email && (
                                    <span className="label-error">
                                        Você precisa informar um E-mail válido
                                    </span>
                                )}</Col>

                        </Row>
                        <Row className="mb-3">
                            <Col><Form.Label>Telefone (opcional)</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="Telefone"
                                    {...register('phone')}
                                />
                                {errors.email && (
                                    <span className="label-error">
                                        Você precisa informar um Telefone válido
                                    </span>
                                )}</Col>

                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Button
                                        disabled={disableSign}
                                        type="submit"
                                        className="mb-0 w-100"
                                        onClick={() => clearErrors()}
                                    >
                                        Cadastrar Usuário
                                    </Button>
                                </Form.Group>
                            </Col>
                            <Col>
                                {disableSign && (
                                    <Form.Group>
                                        <Button
                                            className="mb-0 btn-success w-100"
                                            onClick={() => resetForm()}
                                        >
                                            Cadastrar Outro
                                        </Button>
                                    </Form.Group>
                                )}
                            </Col>
                        </Row>
                        {/* <Row>
                <p>Não foi possível cadastrar o usuário</p>
              </Row> */}
                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Fechar
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Criar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showOrg} onHide={handleCloseOrg}>
                <Modal.Header closeButton>
                    <Modal.Title>Editar Evento</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row className="mb-3">

                            <Col>
                                <Form.Label>Nome</Form.Label>
                                <Form.Control
                                    placeholder="Nome Completo"
                                    {...register('name', { required: true })}
                                />
                                {errors.name && (
                                    <span className="label-error">
                                        Você precisa informar um Nome.
                                    </span>
                                )}</Col>
                        </Row>
                        <Row className="mb-3">
                            <Col><Form.Label>Local</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder=""
                                    {...register('email')}
                                />
                            </Col>

                        </Row>
                        <Row className="mb-3">
                            <Col><Form.Label>Data/Hora</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder=""
                                    {...register('phone')}
                                />
                                {errors.email && (
                                    <span className="label-error">
                                        Você precisa informar um Telefone válido
                                    </span>
                                )}</Col>

                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Button
                                        disabled={disableSign}
                                        type="submit"
                                        className="mb-0 w-100"
                                        onClick={() => clearErrors()}
                                    >
                                        Atualizar
                                    </Button>
                                </Form.Group>
                            </Col>

                        </Row>
                        {/* <Row>
                <p>Não foi possível cadastrar o usuário</p>
              </Row> */}
                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseOrg}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal show={showConfirm} onHide={handleCloseConfirm}>
                <Modal.Body>
                    Tem certeza?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseConfirm}>
                        Sair
                    </Button>
                    <Button variant="danger" onClick={handleCloseConfirm}>
                        Excluir
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Events
